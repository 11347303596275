export const MenuItems = [
  {
    title: "STRONA GŁÓWNA",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "INWESTYCJA",
    url: "/#about",
    cName: "nav-links",
  },
  {
    title: "KONTAKT",
    url: "/#contact",
    cName: "nav-links",
  },
  // {
  //     title : "Lokale",
  //     url : "/",
  //     cName : "nav-links"
  // },
  // {
  //     title : "Dlaczego My",
  //     url : "/#our",
  //     cName : "nav-links"
  // },
]
