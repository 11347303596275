import styled from "styled-components"
import { FaUser, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa"

const icon = `
  margin-right: 1rem;
  font-size: 3rem;

  & > path {
    fill: #2f4f4f;
  }
`

export const UserIcon = styled(FaUser)`
  ${icon}
`

export const EnvelopeIcon = styled(FaEnvelope)`
  ${icon}
`

export const MapIcon = styled(FaMapMarkerAlt)`
  ${icon}
`

export const ContactUs = styled.div`
  width: 80%;
  margin: auto;

  @media (max-width: 720px) {
    width: 90%;
    margin: 20px auto;
  }
`

export const Row = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 720px) {
    margin-top: 2%;
  }
`

export const ContactCol = styled.div`
  flex-basis: 48%;
  margin-bottom: 30px;

  & h2 {
    color: #2f4f4f;
    position: relative;
    top: -40px;
    padding-top: 30px;

    @media (max-width: 720px) {
      font-size: 1.5rem;
      top: -20px;
    }
  }

  & input,
  & textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;

    @media (max-width: 720px) {
      padding: 12px;
      font-size: 1rem;
    }
  }

  & button {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #2f4f4f;
    padding: 12px 34px;
    font-size: 13px;
    background: #2f4f4f;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    @media (max-width: 720px) {
      padding: 10px 28px;
      font-size: 12px;
    }
  }

  & button:hover {
    border: 1px solid #2f4f4f;
    background: #fff;
    cursor: pointer;
    color: #2f4f4f;
  }
`

export const Coc = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
  margin-bottom: 30px;

  & p {
    padding: 0.2rem 0;
    font-size: 1rem;

    @media (max-width: 720px) {
      font-size: 0.9rem;
    }
  }

  & h5 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;

    @media (max-width: 720px) {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & a {
    color: #555;
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #93695a;
    }
  }
`

export const Location = styled.div`
  width: 100vw;
  margin: auto;

  & iframe {
    width: 100%;
    height: 450px;

    @media (max-width: 720px) {
      height: 300px;
    }
  }
`
